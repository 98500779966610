import React, { useState, useEffect } from 'react';
import { auth, createUserDocument, getUserDocument, updateUserTokens, signInWithGoogle } from './firebase';
import { User } from 'firebase/auth';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './markdown.css';
import { SubscriptionPlans } from './components/SubscriptionPlans';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PaymentSuccess } from './components/PaymentSuccess';

interface UserData {
  uid: string;
  email: string;
  tokens: number;
  subscriptionStatus: string;
}

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [transcription, setTranscription] = useState('');
  const [summary, setSummary] = useState('');
  const [promptType, setPromptType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const userDoc = await createUserDocument(user);
        setUserData(userDoc as UserData);
      } else {
        setUserData(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const extractVideoId = (url: string): string | null => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const handleGetTranscription = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userData || userData.tokens <= 0) {
      setError("You don't have enough tokens. Please upgrade your plan.");
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      const videoId = extractVideoId(videoUrl);
      if (!videoId) {
        throw new Error('Invalid YouTube URL');
      }
      const apiUrl = process.env.REACT_APP_API_URL || 'https://api.appfreemo.com';
      console.log(`Fetching transcript for video ID: ${videoId}`);
      const response = await fetch(`${apiUrl}/api/transcript/${videoId}`);
      
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);
      
      const responseText = await response.text();
      console.log('Response text:', responseText);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      try {
        const data = JSON.parse(responseText);
        setTranscription(data.text || responseText);
      } catch (jsonError) {
        console.warn('Response is not JSON, using text as is:', responseText);
        setTranscription(responseText);
      }
      
      // After successful transcription:
      await updateUserTokens(userData.uid, userData.tokens - 1);
      setUserData({ ...userData, tokens: userData.tokens - 1 });
    } catch (error: any) {
      console.error('Full error:', error);
      setError(`Failed to fetch transcription: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSummarize = async () => {
    if (!userData || userData.tokens <= 0) {
      setError("You don't have enough tokens. Please upgrade your plan.");
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://api.appfreemo.com';
      const response = await fetch(`${apiUrl}/api/summarize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transcription,
          prompt_type: promptType,
        }),
      });
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('Summarize endpoint not found. Check your API routes.');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setSummary(data.summary);
      // After successful summarization:
      await updateUserTokens(userData.uid, userData.tokens - 1);
      setUserData({ ...userData, tokens: userData.tokens - 1 });
    } catch (error: any) {
      if (error instanceof Error) {
        console.error('Error summarizing:', error);
        setError(`Failed to summarize: ${error.message}`);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const escapedSummary = summary.replace(/=/g, '\\=');

  const handleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.error("Failed to sign in:", error);
      setError("Failed to sign in. Please try again.");
    }
  };

  const testApiConnection = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';
      const response = await fetch(`${apiUrl}/test`);
      const data = await response.json();
      console.log('Test API response:', data);
    } catch (error) {
      console.error('Error testing API:', error);
    }
  };

  useEffect(() => {
    testApiConnection();
  }, []);

  return (
    <Router>
      <div className="app">
        <header className="header">
          <div className="header-content">
            <div className="logo">TranscriptEase</div>
            {userData ? (
              <button className="auth-btn" onClick={() => auth.signOut()}>Sign Out</button>
            ) : (
              <button className="auth-btn" onClick={handleSignIn}>Sign In with Google</button>
            )}
          </div>
        </header>
        
        <Routes>
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/" element={
            <div className="main-container">
              {userData && (
                <aside className="sidebar">
                  <div className="account-info-block">
                    <h3 className="text-xl font-bold mb-2">Account Info</h3>
                    <div className="info-row">
                      <span>Tokens:</span>
                      <span>{userData.tokens}</span>
                    </div>
                    <div className="info-row">
                      <span>Subscription:</span>
                      <span>{userData.subscriptionStatus}</span>
                    </div>
                  </div>
                  {userData.subscriptionStatus === 'free' && (
                    <SubscriptionPlans showPlans={['basic', 'pro']} />
                  )}
                  {userData.subscriptionStatus === 'basic' && (
                    <SubscriptionPlans showPlans={['pro']} />
                  )}
                </aside>
              )}
              
              <main className="main-content">
                <div className="card transcription-card">
                  <form onSubmit={handleGetTranscription}>
                    <input
                      type="text"
                      value={videoUrl}
                      onChange={(e) => setVideoUrl(e.target.value)}
                      placeholder="Enter YouTube video URL"
                      className="url-input"
                    />
                    <button
                      type="submit"
                      className="action-btn"
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading...' : 'Get Transcription'}
                    </button>
                  </form>
                  <textarea
                    value={transcription}
                    readOnly
                    className="transcription-output"
                    placeholder="Transcription will appear here"
                  />
                </div>
                
                <div className="card summary-card">
                  <select
                    value={promptType}
                    onChange={(e) => setPromptType(e.target.value)}
                    className="summary-select"
                  >
                    <option value="">Select an option</option>
                    <option value="bullet_points">Bullet Points</option>
                    <option value="short_summary">Short Summary</option>
                    <option value="detailed_summary">Detailed Summary</option>
                    <option value="500 Word Markdown Blog">500 Word Markdown Blog</option>
                    <option value="500 Word HTML Blog">500 Word HTML Blog</option>
                  </select>
                  <button
                    onClick={handleSummarize}
                    className="action-btn"
                    disabled={isLoading || !transcription || !promptType}
                  >
                    Submit
                  </button>
                </div>
                
                {summary && (
                  <div className="card summary-result">
                    <h2 className="summary-title">Summary:</h2>
                    <div className="markdown-body">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          code: ({ node, inline, className, children, ...props }: any) => {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline ? (
                              <pre className="code-block">
                                <code className={className} {...props}>
                                  {children}
                                </code>
                              </pre>
                            ) : (
                              <code className="inline-code" {...props}>
                                {children}
                              </code>
                            )
                          },
                          em: ({ node, ...props }: any) => <span className="italic" {...props} />,
                          strong: ({ node, ...props }: any) => <span className="font-bold" {...props} />
                        }}
                      >
                        {escapedSummary}
                      </ReactMarkdown>
                    </div>
                  </div>
                )}
                
                {error && <p className="error-message">{error}</p>}
                {isLoading && <p className="loading-message">Loading...</p>}
              </main>
            </div>
          } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;