import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface SessionDetails {
  id: string;
}

export const PaymentSuccess: React.FC = () => {
  const [sessionDetails, setSessionDetails] = useState<SessionDetails | null>(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    if (sessionId) {
      // Here you would typically make an API call to your backend to verify the session
      // For now, we'll just display the session ID
      setSessionDetails({ id: sessionId });
    }
  }, [location]);

  return (
    <div>
      <h1>Payment Successful!</h1>
      {sessionDetails ? (
        <p>Your payment session ID is: {sessionDetails.id}</p>
      ) : (
        <p>Loading payment details...</p>
      )}
    </div>
  );
};