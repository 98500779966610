import React from 'react';
import { useLoggedInUser } from './AuthWrapper';
import { loadStripe } from '@stripe/stripe-js';

interface Plan {
  id: string;
  name: string;
  price: number;
  credits: number;
}

interface SubscriptionPlansProps {
  showPlans: string[];
}

const allPlans: Plan[] = [
  { id: 'basic', name: 'Basic Plan', price: 9.99, credits: 40 },
  { id: 'pro', name: 'Pro Plan', price: 19.99, credits: 100 },
];

export const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({ showPlans }) => {
  const user = useLoggedInUser();

  const handleSubscribe = async (planId: string) => {
    if (!user) {
      alert('Please sign in to subscribe');
      return;
    }

    console.log(`Attempting to subscribe to plan: ${planId} for user: ${user.uid}`);

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://api.appfreemo.com';
      console.log(`Sending request to: ${apiUrl}/api/stripe/create-checkout-session`);
      const response = await fetch(`${apiUrl}/api/stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          plan_id: planId,
          user_id: user.uid,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received response:', data);

      if (!data.sessionId) {
        throw new Error('No sessionId received from server');
      }

      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
      const result = await stripe?.redirectToCheckout({ sessionId: data.sessionId });

      if (result?.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('Failed to create checkout session. Please try again.');
    }
  };

  const filteredPlans = allPlans.filter(plan => showPlans.includes(plan.id));

  return (
    <div className="subscription-plans">
      {filteredPlans.map((plan) => (
        <div key={plan.id} className="plan-block">
          <div className="mb-2">
            <button
              onClick={() => handleSubscribe(plan.id)}
              className="w-full bg-transparent border-2 border-white text-white py-2 px-4 rounded-full hover:bg-white hover:text-blue-500 transition-colors duration-300"
            >
              {plan.name}
            </button>
          </div>
          <div className="flex justify-between items-center text-sm">
            <span>${plan.price.toFixed(2)}</span>
            <span>{plan.credits} tokens</span>
          </div>
        </div>
      ))}
    </div>
  );
};