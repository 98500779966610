import { initializeApp } from "firebase/app";
import { getAuth, User, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    await createUserDocument(user);
    return user;
  } catch (error) {
    console.error("Error signing in with Google: ", error);
    throw error;
  }
};

// Utility function to create or update user document
export const createUserDocument = async (user: User, additionalData = {}) => {
  if (!user) return;

  const userRef = doc(db, `Users/${user.uid}`);
  const snapshot = await getDoc(userRef);

  if (!snapshot.exists()) {
    const { email } = user;
    const createdAt = new Date();

    try {
      await setDoc(userRef, {
        email,
        createdAt,
        tokens: 10, // Initial free tokens
        subscriptionStatus: 'free',
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }

  return getUserDocument(user.uid);
};

// Utility function to get user document
export const getUserDocument = async (uid: string) => {
  if (!uid) return null;
  try {
    const userDocument = await getDoc(doc(db, `Users/${uid}`));
    return {
      uid,
      ...userDocument.data()
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

// Utility function to update user tokens
export const updateUserTokens = async (uid: string, tokens: number) => {
  const userRef = doc(db, `Users/${uid}`);
  await updateDoc(userRef, { tokens });
};

// Utility function to update user subscription status
export const updateUserSubscription = async (uid: string, status: string) => {
  const userRef = doc(db, `Users/${uid}`);
  await updateDoc(userRef, { subscriptionStatus: status });
};