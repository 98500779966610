import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { FirebaseError } from "firebase/app";
import {
  GoogleAuthProvider,
  type User,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import type React from "react";
import { useEffect, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { Brain } from "../brain/Brain";
import { app, auth, db } from "../firebase"; // Import from firebase.ts

const provider = new GoogleAuthProvider();
provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

const signInWithGoogle = async (): Promise<User | null> => {
  if (!auth) return null;

  try {
    const result = await signInWithPopup(auth, provider);
    return result.user;
  } catch (error: unknown) {
    // Ignore errors when user closes login window
    const shouldIgnoreError =
      error instanceof FirebaseError &&
      ["auth/popup-closed-by-user", "auth/cancelled-popup-request"].includes(
        error.code,
      );

    if (shouldIgnoreError) {
      return null;
    }

    throw error;
  }
};

export interface Props {
  children: React.ReactNode;
  title?: string;
  google?: boolean;
  email?: boolean;
}

interface FormData {
  email: string;
  password: string;
}

export const AuthWrapper = ({
  children,
  google = true,
  email = true,
  title = "Welcome",
}: Props) => {
  const user = useLoggedInUser();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [isSigningIn, setIsSigningIn] = useState(false);

  const showDivider = google && email;

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setIsSigningIn(true);
    try {
      await signInWithEmailAndPassword(auth!, data.email, data.password);
      reset();
    } catch (err: unknown) {
      try {
        await createUserWithEmailAndPassword(auth!, data.email, data.password);
        reset();
      } catch (err: unknown) {
        console.error(err);
        toast({
          status: "error",
          title: "Could not sign in. Please try again.",
        });
      }
    } finally {
      setIsSigningIn(false);
    }
  };

  if (user) {
    return <>{children}</>;
  }

  return (
    <div className="flex w-full justify-center mt-20">
      <div className="flex flex-col gap-10">
        <Heading className="text-center">{title}</Heading>

        <div className="flex flex-col gap-10" style={{ width: "600px" }}>
          {email && (
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl isInvalid={!!errors.email?.message}>
                <FormLabel>Email</FormLabel>
                <Input
                  {...register("email", {
                    validate: {
                      isValidEmail: (it) => {
                        if (/^\S+@\S+\.\S+$/i.test(it)) {
                          return true;
                        }

                        return "Please enter a valid email address";
                      },
                      required: (it) =>
                        it.length > 0 ? true : "This field is required.",
                    },
                  })}
                  placeholder="email@example.com"
                  type="email"
                />
                {errors.email?.message && (
                  <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.password?.message}>
                <FormLabel>Password</FormLabel>
                <Input
                  {...register("password", {
                    validate: {
                      isOkPassword: (it) => {
                        if (it.length >= 8) {
                          return true;
                        }

                        return "Minimum length is 8 characters.";
                      },
                      required: (it) =>
                        it.length > 0 ? true : "This field is required.",
                    },
                  })}
                  placeholder="********"
                  type="password"
                />
                {errors.password?.message && (
                  <FormErrorMessage>{errors.password.message}</FormErrorMessage>
                )}
              </FormControl>

              <Button
                className="flex gap-2"
                type="submit"
                disabled={isSigningIn}
              >
                {isSigningIn ? (
                  <>
                    Signing in...{" "}
                    <Spinner size="sm" speed="0.65s" emptyColor="gray.200" />
                  </>
                ) : (
                  "Continue"
                )}
              </Button>
            </form>
          )}

          {showDivider && (
            <Box position="relative">
              <Divider />
              <AbsoluteCenter bg="white" px="4">
                Or
              </AbsoluteCenter>
            </Box>
          )}

          {google && (
            <Button type="button" onClick={signInWithGoogle}>
              Continue with Google
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export const useLoggedInUser = (): User | null => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (!auth) {
      console.error(
        "Firebase Auth service is not initialized.\nCheck out https://docs.databutton.com/databutton-tips-and-tricks/adding-authentication",
      );
      return;
    }

    const unsubscribe = auth?.onAuthStateChanged((user: User | null) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return user;
};

export const signOut = async () => {
  if (auth) {
    await firebaseSignOut(auth);
  } else {
    console.error("Sign out failed: Firebase Auth service is not initialized.");
  }
};

export const authedBrain = {
  ...Brain,
  baseUrl: `${window.location.origin}/api`,
  baseApiParams: {
    credentials: "include",
    secure: true,
  },
  securityWorker: async () => {
    if (!auth || !auth.currentUser) {
      console.error("No authentication token available.");
      return {};
    }
    const idToken = await auth.currentUser.getIdToken();
    return {
      headers: {
        "x-authorization": `Bearer ${idToken}`,
      },
    };
  },
};

export { app, db, auth };