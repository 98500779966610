import YouTubeTranscript from 'youtube-transcript-api';

export const Brain = {
  summarize_transcription: async ({ transcription, prompt_type }: { transcription: string, prompt_type?: string }) => {
    // Implement your summarization logic here
    console.log('Summarizing:', transcription, prompt_type);
    return { json: async () => ({ summary: "Placeholder summary" }) };
  },
  get_transcript: async ({ videoId }: { videoId: string }) => {
    try {
      const transcript = await YouTubeTranscript.fetchTranscript(videoId);
      return {
        json: async () => transcript.map(item => ({
          text: item.text,
          start: item.start,
          duration: item.duration
        }))
      };
    } catch (error) {
      console.error('Error fetching transcript:', error);
      return { json: async () => [{ text: "Error fetching transcript" }] };
    }
  }
};